<template>
  <div>
    <b-card
      border-variant="success"
      style="width: 25rem"
      @click="activityDetails"
    >
      <b-card-title>{{ $d(new Date(activity.startAt), "long") }}</b-card-title>
      <b-card-body>{{
        $t("activity.timeline." + activity.type + "." + activity.status, {
          name: activity.customer.fullname,
        })
      }}</b-card-body>
    </b-card>
    <b-modal
      hide-footer
      scrollable
      size="xl"
      header-bg-variant="success"
      :id="'modal-' + activity._id"
      :title="$t('activity.details.modal_title')"
    >
      <ActivityDetails :activity="activity" />
    </b-modal>
  </div>
</template>

<script>
import ActivityDetails from "@/components/Activity/ActivityDetails";
export default {
  name: "ActivityTimelineItem",
  components: {
    ActivityDetails,
  },
  props: ["activity"],
  methods: {
    activityDetails: function () {
      this.$bvModal.show("modal-" + this.activity._id);
    },
  },
};
</script>
