<template>
  <div>
    <ActivityTimeLine :id="$route.params.id" type="shop" />
  </div>
</template>

<script>
import ActivityTimeLine from "@/components/Activity/ActivityTimeline";
export default {
  name: "ShopTimeline",
  components: { ActivityTimeLine },
};
</script>
