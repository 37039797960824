<template>
  <div>
    <b-card
      :title="$t('activity.details.shopData.title')"
      border-variant="success"
    >
      <b-container>
        <b-row>
          <template v-if="activity.shopData.getApvKeys || full">
            <b-col cols="4">{{
              $t("activity.details.shopData.getApvKeys")
            }}</b-col>
            <b-col cols="8">
              <b-icon-exclamation-circle-fill
                variant="danger"
                v-if="!activity.shopData.getApvKeys"
              />
              <b-icon-check-circle-fill variant="success" v-else />
            </b-col>
          </template>
          <template v-if="activity.shopData.setApvKeys || full">
            <b-col cols="4">{{
              $t("activity.details.shopData.setApvKeys")
            }}</b-col>
            <b-col cols="8">
              <b-icon-exclamation-circle-fill
                variant="danger"
                v-if="!activity.shopData.setApvKeys"
              />
              <b-icon-check-circle-fill variant="success" v-else />
            </b-col>
          </template>
          <template v-if="activity.shopData.getApvCard || full">
            <b-col cols="4">{{
              $t("activity.details.shopData.getApvCard")
            }}</b-col>
            <b-col cols="8">
              <b-icon-exclamation-circle-fill
                variant="danger"
                v-if="!activity.shopData.getApvCard"
              />
              <b-icon-check-circle-fill variant="success" v-else />
            </b-col>
          </template>
          <template v-if="activity.shopData.setApvCard || full">
            <b-col cols="4">{{
              $t("activity.details.shopData.setApvCard")
            }}</b-col>
            <b-col cols="8">
              <b-icon-exclamation-circle-fill
                variant="danger"
                v-if="!activity.shopData.setApvCard"
              />
              <b-icon-check-circle-fill variant="success" v-else />
            </b-col>
          </template>
          <template v-if="activity.shopData.getAssistanceKeys || full">
            <b-col cols="4">{{
              $t("activity.details.shopData.getAssistanceKeys")
            }}</b-col>
            <b-col cols="8">
              <b-icon-exclamation-circle-fill
                variant="danger"
                v-if="!activity.shopData.getAssistanceKeys"
              />
              <b-icon-check-circle-fill variant="success" v-else />
            </b-col>
          </template>
          <template v-if="activity.shopData.getAssistanceCard || full">
            <b-col cols="4">{{
              $t("activity.details.shopData.getAssistanceCard")
            }}</b-col>
            <b-col cols="8">
              <b-icon-exclamation-circle-fill
                variant="danger"
                v-if="!activity.shopData.getAssistanceCard"
              />
              <b-icon-check-circle-fill variant="success" v-else />
            </b-col>
          </template>
          <template v-if="activity.shopData.getCourtesyKeys || full">
            <b-col cols="4">{{
              $t("activity.details.shopData.getCourtesyKeys")
            }}</b-col>
            <b-col cols="8">
              <b-icon-exclamation-circle-fill
                variant="danger"
                v-if="!activity.shopData.getCourtesyKeys"
              />
              <b-icon-check-circle-fill variant="success" v-else />
            </b-col>
          </template>
          <template v-if="activity.shopData.setCourtesyKeys || full">
            <b-col cols="4">{{
              $t("activity.details.shopData.setCourtesyKeys")
            }}</b-col>
            <b-col cols="8">
              <b-icon-exclamation-circle-fill
                variant="danger"
                v-if="!activity.shopData.setCourtesyKeys"
              />
              <b-icon-check-circle-fill variant="success" v-else />
            </b-col>
          </template>
          <template v-if="activity.shopData.getRentKeys || full">
            <b-col cols="4">{{
              $t("activity.details.shopData.getRentKeys")
            }}</b-col>
            <b-col cols="8">
              <b-icon-exclamation-circle-fill
                variant="danger"
                v-if="!activity.shopData.getRentKeys"
              />
              <b-icon-check-circle-fill variant="success" v-else />
            </b-col>
          </template>
          <template v-if="activity.shopData.setRentKeys || full">
            <b-col cols="4">{{
              $t("activity.details.shopData.setRentKeys")
            }}</b-col>
            <b-col cols="8">
              <b-icon-exclamation-circle-fill
                variant="danger"
                v-if="!activity.shopData.setRentKeys"
              />
              <b-icon-check-circle-fill variant="success" v-else />
            </b-col>
          </template>
          <template v-if="activity.shopData.customerMobilePhone || full">
            <b-col cols="4">{{
              $t("activity.details.shopData.customerMobilePhone")
            }}</b-col>
            <b-col cols="8">
              <b-icon-exclamation-circle-fill
                variant="danger"
                v-if="!activity.shopData.customerMobilePhone"
              />
              <b-icon-check-circle-fill variant="success" v-else />
            </b-col>
          </template>
          <template v-if="activity.shopData.customerEmail || full">
            <b-col cols="4">{{
              $t("activity.details.shopData.customerEmail")
            }}</b-col>
            <b-col cols="8">
              <b-icon-exclamation-circle-fill
                variant="danger"
                v-if="!activity.shopData.customerEmail"
              />
              <b-icon-check-circle-fill variant="success" v-else />
            </b-col>
          </template>
        </b-row>
      </b-container>
    </b-card>
  </div>
</template>
<script>
export default {
  props: ["activity"],
  data() {
    return {
      full: false,
    };
  },
};
</script>
