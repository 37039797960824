<template>
  <div>
    <b-card
      :title="$t('activity.details.customerData.title')"
      border-variant="success"
    >
      <b-container>
        <b-row>
          <template v-if="activity.customerData.restitutionRequested || full">
            <b-col cols="5">{{
              $t("activity.details.customerData.restitutionRequested")
            }}</b-col>
            <b-col cols="7">
              <b-icon-exclamation-circle-fill
                variant="danger"
                v-if="!activity.customerData.restitutionRequested"
              />
              <b-icon-check-circle-fill variant="success" v-else />
            </b-col>
          </template>
          <template v-if="activity.customerData.setApvKeys || full">
            <b-col cols="5">{{
              $t("activity.details.customerData.setApvKeys")
            }}</b-col>
            <b-col cols="7">
              <b-icon-exclamation-circle-fill
                variant="danger"
                v-if="!activity.customerData.setApvKeys"
              />
              <b-icon-check-circle-fill variant="success" v-else />
            </b-col>
          </template>
          <template v-if="activity.customerData.getApvKeys || full">
            <b-col cols="5">{{
              $t("activity.details.customerData.getApvKeys")
            }}</b-col>
            <b-col cols="7">
              <b-icon-exclamation-circle-fill
                variant="danger"
                v-if="!activity.customerData.getApvKeys"
              />
              <b-icon-check-circle-fill variant="success" v-else />
            </b-col>
          </template>
          <template v-if="activity.customerData.setApvCard || full">
            <b-col cols="5">{{
              $t("activity.details.customerData.setApvCard")
            }}</b-col>
            <b-col cols="7">
              <b-icon-exclamation-circle-fill
                variant="danger"
                v-if="!activity.customerData.setApvCard"
              />
              <b-icon-check-circle-fill variant="success" v-else />
            </b-col>
          </template>
          <template v-if="activity.customerData.getApvCard || full">
            <b-col cols="5">{{
              $t("activity.details.customerData.getApvCard")
            }}</b-col>
            <b-col cols="7">
              <b-icon-exclamation-circle-fill
                variant="danger"
                v-if="!activity.customerData.getApvCard"
              />
              <b-icon-check-circle-fill variant="success" v-else />
            </b-col>
          </template>
          <template v-if="activity.customerData.setAssistanceKeys || full">
            <b-col cols="5">{{
              $t("activity.details.customerData.setAssistanceKeys")
            }}</b-col>
            <b-col cols="7">
              <b-icon-exclamation-circle-fill
                variant="danger"
                v-if="!activity.customerData.setAssistanceKeys"
              />
              <b-icon-check-circle-fill variant="success" v-else />
            </b-col>
          </template>
          <template v-if="activity.customerData.setAssistanceCard || full">
            <b-col cols="5">{{
              $t("activity.details.customerData.setAssistanceCard")
            }}</b-col>
            <b-col cols="7">
              <b-icon-exclamation-circle-fill
                variant="danger"
                v-if="!activity.customerData.setAssistanceCard"
              />
              <b-icon-check-circle-fill variant="success" v-else />
            </b-col>
          </template>
          <template v-if="activity.customerData.getCourtesyKeys || full">
            <b-col cols="5">{{
              $t("activity.details.customerData.getCourtesyKeys")
            }}</b-col>
            <b-col cols="7">
              <b-icon-exclamation-circle-fill
                variant="danger"
                v-if="!activity.customerData.getCourtesyKeys"
              />
              <b-icon-check-circle-fill variant="success" v-else />
            </b-col>
          </template>
          <template v-if="activity.customerData.setCourtesyKeys || full">
            <b-col cols="5">{{
              $t("activity.details.customerData.setCourtesyKeys")
            }}</b-col>
            <b-col cols="7">
              <b-icon-exclamation-circle-fill
                variant="danger"
                v-if="!activity.customerData.setCourtesyKeys"
              />
              <b-icon-check-circle-fill variant="success" v-else />
            </b-col>
          </template>
          <template v-if="activity.customerData.getRentKeys || full">
            <b-col cols="5">{{
              $t("activity.details.customerData.getRentKeys")
            }}</b-col>
            <b-col cols="7">
              <b-icon-exclamation-circle-fill
                variant="danger"
                v-if="!activity.customerData.getRentKeys"
              />
              <b-icon-check-circle-fill variant="success" v-else />
            </b-col>
          </template>
          <template v-if="activity.customerData.setRentKeys || full">
            <b-col cols="5">{{
              $t("activity.details.customerData.setRentKeys")
            }}</b-col>
            <b-col cols="7">
              <b-icon-exclamation-circle-fill
                variant="danger"
                v-if="!activity.customerData.setRentKeys"
              />
              <b-icon-check-circle-fill variant="success" v-else />
            </b-col>
          </template>
          <template v-if="activity.customerData.otherMobilePhone != ''">
            <b-col cols="5">{{
              $t("activity.details.customerData.otherMobilePhone")
            }}</b-col>

            <b-col cols="7" style="color: red">{{
              activity.customerData.otherMobilePhone
            }}</b-col>
          </template>
          <template v-if="activity.customerData.otherEmail != ''">
            <b-col cols="5">{{
              $t("activity.details.customerData.otherEmail")
            }}</b-col>
            <b-col cols="7" style="color: red">{{
              activity.customerData.otherEmail
            }}</b-col>
          </template>
          <template v-if="activity.customerData.otherMileage != ''">
            <b-col cols="5">{{
              $t("activity.details.customerData.otherMileage")
            }}</b-col>
            <b-col cols="7" style="color: red">{{
              activity.customerData.otherMileage
            }}</b-col>
          </template>
          <template v-if="activity.customerData.message != ''">
            <b-col cols="5">{{
              $t("activity.details.customerData.message")
            }}</b-col>
            <b-col cols="7" style="color: red">{{
              activity.customerData.message
            }}</b-col>
          </template>
        </b-row>
      </b-container>
    </b-card>
  </div>
</template>
<script>
export default {
  props: ["activity"],
  data() {
    return {
      full: false,
    };
  },
};
</script>
