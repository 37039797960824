<template>
  <div>
    <b-card
      :title="$t('activity.details.customer.title')"
      border-variant="success"
    >
      <b-container>
        <b-row>
          <b-col cols="5">{{ $t("activity.details.customer.lastname") }}</b-col
          ><b-col cols="7">{{ activity.customer.lastname }}</b-col>
          <b-col cols="5">{{ $t("activity.details.customer.firstname") }}</b-col
          ><b-col cols="7">{{ activity.customer.firstname }}</b-col>
          <b-col cols="5">{{
            $t("activity.details.customer.mobilePhone")
          }}</b-col
          ><b-col cols="7">{{ activity.customer.mobilePhone }}</b-col>
          <b-col cols="5">{{ $t("activity.details.customer.email") }}</b-col
          ><b-col cols="7">{{ activity.customer.email }}</b-col>
        </b-row>
      </b-container>
    </b-card>
  </div>
</template>
<script>
export default {
  props: ["activity"],
};
</script>
