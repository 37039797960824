<template>
  <div>
    <b-card
      :title="$t('activity.details.vehicle.title')"
      border-variant="success"
    >
      <b-container>
        <b-row>
          <b-col cols="5">{{ $t("activity.details.vehicle.brand") }}</b-col
          ><b-col cols="7">{{ activity.vehicle.brand }}</b-col>
          <b-col cols="5">{{ $t("activity.details.vehicle.model") }}</b-col
          ><b-col cols="7">{{ activity.vehicle.model }}</b-col>
          <b-col cols="5">{{ $t("activity.details.vehicle.immat") }}</b-col
          ><b-col cols="7">{{ activity.vehicle.immat }}</b-col>
          <b-col cols="5">{{ $t("activity.details.vehicle.vin") }}</b-col
          ><b-col cols="7">{{ activity.vehicle.vin }}</b-col>
          <b-col cols="5">{{ $t("activity.details.vehicle.mileage") }}</b-col
          ><b-col cols="7">{{ activity.vehicle.mileage }}</b-col>
        </b-row>
      </b-container>
    </b-card>
  </div>
</template>
<script>
export default {
  props: ["activity"],
};
</script>
