<template>
  <b-card
    :title="$t('activity.details.appointment.title')"
    border-variant="success"
  >
    <b-container>
      <b-row>
        <b-col cols="5">{{
          $t("activity.details.appointment.dateReception")
        }}</b-col>
        <b-col cols="7">{{
          $d(new Date(activity.dateReception), "long")
        }}</b-col>
        <b-col cols="5">{{
          $t("activity.details.appointment.dateRestitution")
        }}</b-col>
        <b-col cols="7">{{
          $d(new Date(activity.dateRestitution), "long")
        }}</b-col>
        <b-col cols="5">{{
          $t("activity.details.appointment.receptionAllowed")
        }}</b-col>
        <b-col cols="7">
          <b-icon-exclamation-circle-fill
            variant="danger"
            v-if="!activity.options.receptionAllowed"
          />
          <b-icon-check-circle-fill variant="success" v-else />
        </b-col>

        <b-col cols="5">{{
          $t("activity.details.appointment.restitutionAllowed")
        }}</b-col>
        <b-col cols="7">
          <b-icon-exclamation-circle-fill
            variant="danger"
            v-if="!activity.options.restitutionAllowed"
          />
          <b-icon-check-circle-fill variant="success" v-else />
        </b-col>
      </b-row>
    </b-container>
  </b-card>
</template>
<script>
export default {
  props: ["activity"],
};
</script>
