<template>
  <div>
    <span v-if="activities.length != 0">
      <b-card-group deck>
        <ActivityTimelineItem
          v-for="activity in activities"
          :key="activity._id"
          :activity="activity"
        />
      </b-card-group>
    </span>
    <span v-else>{{ $t("activity.timeline.no_activity") }}</span>
  </div>
</template>

<script>
import ActivityTimelineItem from "@/components/Activity/ActivityTimelineItem";
export default {
  name: "ActivityTimeline",
  components: {
    ActivityTimelineItem,
  },
  props: ["id", "type"],
  data() {
    return {
      activities: [],
    };
  },
  mounted() {
    if (this.type != "shop" && this.type != "device") {
      this.makeToast(
        this.$t("activity.timeline.invalid_type.title"),
        this.$t("activity.timeline.invalid_type.msg")
      );
    } else {
      this.$http({
        url:
          process.env.VUE_APP_API_BASE_URL +
          this.$api.routes[process.env.VUE_APP_API_VERSION || "v1"][
            this.type + "_timeline"
          ].replace(":id", this.id),
        method: "GET",
      }).then((resp) => {
        this.activities = resp.data;
      });
    }
  },
};
</script>
