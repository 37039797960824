<template>
  <b-card
    :title="$t('activity.details.validity.title')"
    border-variant="success"
  >
    <b-container>
      <b-row>
        <b-col cols="5">{{ $t("activity.details.validity.notBefore") }}</b-col>
        <b-col cols="7">{{ $d(new Date(activity.notBefore), "long") }}</b-col>
        <b-col cols="5">{{ $t("activity.details.validity.expiresAt") }}</b-col>
        <b-col cols="7">{{ $d(new Date(activity.expiresAt), "long") }}</b-col>
      </b-row>
    </b-container>
  </b-card>
</template>
<script>
export default {
  props: ["activity"],
};
</script>
