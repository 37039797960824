<template>
  <div>
    <b-card
      :title="$t('activity.details.advisorData.title')"
      border-variant="success"
    >
      <b-container>
        <b-row>
          <b-col cols="5">{{
            $t("activity.details.advisorData.rating1")
          }}</b-col>
          <b-col cols="7">
            <b-form-rating v-model="rating1" readonly></b-form-rating>
          </b-col>
          <b-col cols="5">{{
            $t("activity.details.advisorData.rating2")
          }}</b-col>
          <b-col cols="7">
            <b-form-rating v-model="rating1" readonly></b-form-rating>
          </b-col>
          <b-col cols="5">{{
            $t("activity.details.advisorData.rating3")
          }}</b-col>
          <b-col cols="7">
            <b-form-rating v-model="rating1" readonly></b-form-rating>
          </b-col>
          <b-col cols="5">{{
            $t("activity.details.advisorData.comment")
          }}</b-col
          ><b-col cols="7" style="color: red">{{
            activity.advisorData.comment
          }}</b-col>
        </b-row>
      </b-container>
    </b-card>
  </div>
</template>
<script>
export default {
  props: ["activity"],
  data() {
    return {
      rating1: this.activity.advisorData.rating1,
      rating2: this.activity.advisorData.rating2,
      rating3: this.activity.advisorData.rating3,
    };
  },
};
</script>
