<template>
  <b-container>
    <b-row>
      <b-col>
        <ActivityDetailsAppointment :activity="activity" />
        <div class="w-100 p-1" />
        <ActivityDetailsCustomer :activity="activity" />
        <div class="w-100 p-1" />
        <ActivityDetailsVehicle :activity="activity" />
        <div class="w-100 p-1" />
      </b-col>
      <b-col>
        <ActivityDetailsValidity :activity="activity" />
        <div class="w-100 p-1" />
        <ActivityDetailsCustomerData
          :activity="activity"
          v-if="hasCustomerData()"
        />
        <div class="w-100 p-1" />
        <ActivityDetailsCCSData :activity="activity" v-if="hasCCSData()" />
        <div class="w-100 p-1" />
        <ActivityDetailsAdvisor :activity="activity" v-if="hasAdvisorData()" />
        <div class="w-100 p-1" />
        <ActivityDetailsPayments :activity="activity" />
        <div class="w-100 p-1" />
        <ActivityDetailsSMS :activity="activity" />
        <div class="w-100 p-1" />
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
import ActivityDetailsAppointment from "@/components/Activity/Details/ActivityDetailsAppointment";
import ActivityDetailsCustomer from "@/components/Activity/Details/ActivityDetailsCustomer";
import ActivityDetailsVehicle from "@/components/Activity/Details/ActivityDetailsVehicle";
import ActivityDetailsCustomerData from "@/components/Activity/Details/ActivityDetailsCustomerData";
import ActivityDetailsCCSData from "@/components/Activity/Details/ActivityDetailsCCSData";
import ActivityDetailsAdvisor from "@/components/Activity/Details/ActivityDetailsAdvisor";
import ActivityDetailsPayments from "@/components/Activity/Details/ActivityDetailsPayments";
import ActivityDetailsSMS from "@/components/Activity/Details/ActivityDetailsSMS";
import ActivityDetailsValidity from "@/components/Activity/Details/ActivityDetailsValidity";
export default {
  components: {
    ActivityDetailsAppointment,
    ActivityDetailsCustomer,
    ActivityDetailsVehicle,
    ActivityDetailsValidity,
    ActivityDetailsCustomerData,
    ActivityDetailsCCSData,
    ActivityDetailsAdvisor,
    ActivityDetailsPayments,
    ActivityDetailsSMS,
  },
  props: ["activity"],
  methods: {
    //TODO: make real conditions
    hasCustomerData() {
      return true;
    },
    hasCCSData() {
      return true;
    },
    hasAdvisorData() {
      return true;
    },
  },
};
</script>
